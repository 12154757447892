import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import useSaveView from '../../hooks/useSaveView';
import InputUI from '../Interface/InputUI';
import AutoCompleteSelectUI from '../Interface/AutoCompleteSelectUI';
import TodoCard from './TodoCard';
import simpletaskService from '../../service/simpletask.service';
import { useLoader } from '../../provider/LoaderProvider';
import { generateFilter } from '../../utils/helper';
import useMenus from '../../context/MenuContext';
import useView from '../../hooks/useView';
import useParam from '../../context/ParamContext';

const TodoContent = ({ activeTab, type, tabId }) => {
  const saveView = useSaveView();
  const myTodoFilter = useView('myTodoFilter');
  const { getParam, params } = useParam();
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const [submenu, setSubmenu] = useState(null);
  const [myTodo, setMyTodo] = useState([]);
  const [prioValue, setPrioValue] = useState('');
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 20,
        page: 0,
      },
    },
  });
  const [formVal, setFormVal] = useState({
    statusId: [],
    priorId: '',
    simpleTaskName: '',
  });

  const [defaultFilterFields, setDefaultFilterFields] = useState([]);

  const userObj = JSON.parse(localStorage.getItem('user'));
  const announctedTaskStatus = parseInt(params.MYTODO_ANNOUNCED_TASK_STATUS);

  const { showLoader, hideLoader } = useLoader();

  const conditions = {
    like: ['simpleTaskName'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {}, [myTodo]);

  useEffect(() => {
    const newFields = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    if (type === 'own') {
      newFields.push({ field: 'resposiblePersonId', value: userObj.userId });
    } else if (type === 'opened') {
      newFields.push({ field: 'resposiblePersonId', value: null });
      if (announctedTaskStatus) {
        newFields.push({ field: 'statusId', value: announctedTaskStatus });
      }
      //@TODO telephely, szerep stb majd itt kell kezelni
    }

    setDefaultFilterFields(newFields);
  }, [activeTab]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && activeTab === tabId && query.filter?.length > 0) {
      //itt kell eldönteni, hogy lapozás, vagy filter válotozott
      getList();
    }
  }, [query.pagination, query.sorting, query.filter]);

  useEffect(() => {
    if (defaultFilterFields.length > 0) {
      //@TODO átnézni
      const newStatus = formVal.statusId.map((sIds) => sIds.value);
      const newFormVal = { ...formVal, statusId: newStatus };
      const newFilter = generateFilter(newFormVal, conditions);
      if (!_.isEqual(query.filter, [...defaultFilterFields, ...newFilter])) {
        setMyTodo([]);

        setQuery((prev) => ({
          ...prev,
          filter: [...defaultFilterFields, ...newFilter],
        }));
      }
    }
  }, [formVal, defaultFilterFields]);

  useEffect(() => {
    if (myTodoFilter) {
      setFormVal({
        statusId: myTodoFilter.status || [],
        priorId: myTodoFilter.priority?.value || '',
      });
      setPrioValue(myTodoFilter?.priority);
    }
  }, [myTodoFilter]);

  const getList = () => {
    showLoader();
    simpletaskService
      .getTasks(query)
      .then((data) => {
        setMyTodo([]);
        setMyTodo((prev) => [...prev, ...data.rows]);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mx-4 md:w-3/4 lg:1/2  mx-auto">
        <div className="col-span-3 md:col-span-1">
          <InputUI
            variant="standard"
            label={<span className="text-labelColor">Keresés...</span>}
            onChange={(e) => {
              setFormVal((prev) => {
                return { ...prev, simpleTaskName: e.target.value };
              });
            }}
            value={formVal.simpleTaskName}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <AutoCompleteSelectUI
            label={<span className="text-labelColor">Státusz</span>}
            onChange={(_event, newVal, _reason) => {
              setFormVal((prev) => ({
                ...prev,
                statusId: newVal,
              }));
              saveView('myTodoFilter', { status: newVal });
            }}
            selectedValue={formVal.statusId}
            disabled={false}
            id={'selectedStatus'}
            table={['statusitems', 'status']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'statusitems.statusId = status.statusId'}
            freeSolo
            conditions={[`status.subMenuId = ${submenu}`, `statusitems.archive='N'`]}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <AutoCompleteSelectUI
            label={<span className="text-labelColor">Prioritás</span>}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFormVal((prev) => ({
                  ...prev,
                  priorId: '',
                }));
                saveView('myTodoFilter', { priority: '' });
              } else {
                setFormVal((prev) => ({
                  ...prev,
                  priorId: newVal.value,
                }));
                saveView('myTodoFilter', { priority: newVal });
              }
            }}
            value={prioValue}
            disabled={false}
            id={'selectedPrior'}
            table={['prioritylistitems ', 'priority']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'prioritylistitems.priorityId = priority.priorityId'}
            conditions={[`priority.subMenuId = ${submenu}`, `prioritylistitems.archive='N'`]}
          />
        </div>
      </div>
      <div className="sm:flex sm:justify-between"></div>
      <div className="mb-10">
        <div className="py-10 flex flex-wrap gap-7 justify-center">
          {myTodo.map((task, id) => {
            return <TodoCard key={id} task={task} setMyTodo={setMyTodo} submenu={submenu} />;
          })}
        </div>
      </div>
    </>
  );
};

export default TodoContent;
