import { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import appService from '../../service/app.service';
import companyService from '../../service/company.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import FormTabs from './FormTabs';
import CompanyReports from './CompanyReports';
import FormFields from './FormFields';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';

export const CompanyForm = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [memberList, setMemberList] = useState([]);
  const [newMembers, setNewMembers] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const [refetchTable, setRefetchTable] = useState(true);
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    const def = {
      companyId: null,
      companyName: '',
      billPostcode: '',
      billCity: '',
      billAddress: '',
      postCode: '',
      city: '',
      street: '',
      companyDescription: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().nullable(),
    companyName: Yup.string().required(),
    billPostcode: Yup.number().nullable().positive(),
    billCity: Yup.string(),
    billAddress: Yup.string(),
    postCode: Yup.number().nullable(),
    city: Yup.string(),
    street: Yup.string(),
    companyDescription: Yup.string(),
  });

  const valuesToForm = () => {
    const { companyId, companyName, billPostcode, billCity, billAddress, postCode, city, street, companyDescription } =
      selectedValues;

    setInitValues({
      companyId,
      companyName,
      billPostcode,
      billCity,
      billAddress,
      postCode,
      city,
      street,
      companyDescription,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const handleAddTaskMembers = async (taskMembers, insertId) => {
    const tableName = 'SimpleTaskRespPersonlist';
    const id = 'RespPersoncompanyId';
    for (const item of taskMembers) {
      const insertObject = {
        userId: item.userId,
        companyId: item.companyId ?? insertId,
        roleName: item.roleName,
      };

      const insData = { tableName, id, data: insertObject };
      appService
        .addItem(insData)
        .then(() => {
          addNotification({
            content: 'Sikeres mentés!',
            type: NotificationType.SUCCESS,
          });
        })
        .finally(() => {});
    }
    setNewMembers([]);
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
    };

    companyService.updateCompany(updData, selectedValues.companyId).then((_company) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(defaultValues);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setAddField(true);
      setInitValues({});
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
    };

    companyService
      .createCompany(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues(defaultValues);
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setInitValues({});
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    companyService.deleteCompany(selectedValues.companyId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ isSubmitting, values, errors, resetForm }) => (
              <Form>
                <FormFields values={values} errors={errors} viewField={viewField} />

                <FormTabs
                  values={values}
                  companyId={selectedValues?.companyId}
                  errors={errors}
                  disabled={viewField}
                  memberList={memberList}
                  setMemberList={setMemberList}
                  newMembers={newMembers}
                  setNewMembers={setNewMembers}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  formId={'companyDescription'}
                  value={initValues.companyDescription}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <CompanyReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
