import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: () => 'Kötelező!',
    oneOf: () => 'Kötelező!',
    notType: (params) => {
      const errors = {
        number: 'A mezőnek számnak kell lennie!',
        string: 'A mezőnek szövegnek kell lennie!',
        array: 'A mezőnek tömbnek kell lennie!',
        boolean: 'A mezőnek eldöntendőnek kell lennie!',
      };

      return errors[params.type] || 'Helytelen mező típus!';
    },
  },
  string: {
    email: () => 'Helytelen email cím!',
    min: ({ min }) => `Legalább ${min} karakter!`,
    max: ({ max }) => `Legfeljebb ${max} karakter!`,
    matches: () => 'A mező nem megengedett karaktert tartalmaz!',
  },
  number: {
    positive: () => 'Pozitív szám!',
    required: () => 'Kötelező!',
    oneOf: () => 'Kötelező!',
  },
  array: {
    min: ({ min }) => `Legalább ${min} elemet válassz!`,
    max: ({ max }) => `Legfeljebb ${max} elemet válassz!`,
  },
});

export default Yup;
