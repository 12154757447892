import { useState } from 'react';

import TabsUI from '../Interface/TabsUI';

import CompanyActive from './CompanyActive';
import CompanyDayOff from './CompanyDayOff';
import MachineActive from './MachineActive';
import MachineDayOff from './MachineDayOff';

const ActiveTimePage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const todoTabs = [
    {
      id: 0,
      name: 'Cég menetidő',
      content: <CompanyActive isAdditional={false} />,
    },
    {
      id: 1,
      name: 'Cég plusz napok',
      content: <CompanyActive isAdditional={true} />,
    },
    {
      id: 2,
      name: 'Cég szabadnapok',
      content: <CompanyDayOff />,
    },
    {
      id: 3,
      name: 'Gép menetidő',
      content: <MachineActive isAdditional={false} />,
    },
    {
      id: 4,
      name: 'Gép plusz napok',
      content: <MachineActive isAdditional={true} />,
    },
    {
      id: 5,
      name: 'Gép szabadnapok',
      content: <MachineDayOff />,
    },
  ];

  return (
    <div className="fixed top-16 inset-0 gap-4 bg-gray-100 p-4 h-full overflow-x-auto">
      <div>
        <div className="flex justify-center w-full mr-auto ml-auto">
          <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
            {todoTabs.map((tab, index) => (
              <div key={index} name={tab.name}>
                {tab.content}
              </div>
            ))}
          </TabsUI>
        </div>
      </div>
    </div>
  );
};

export default ActiveTimePage;
