import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../service/auth.service';
import { ButtonUI, InputUI } from '../Interface';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import appService from '../../service/app.service';
import usePages from '../../context/PageContext';
import useParam from '../../context/ParamContext';

export default function Auth() {
  const [showMessage, setShowMessage] = useState(false);
  const [loginData, setLoginData] = useState({
    userName: '',
    password: '',
  });

  const { setPages } = usePages();
  const { setUser, user } = useUsers();
  const { setMenus } = useMenus();
  const { setParam } = useParam();

  useEffect(() => {
    //@TODO ezt át kell nézni
    localStorage.setItem('loginStatus', false);
  }, []);

  const findSubMenu = (user, menus) => {
    for (const menu of menus) {
      const subMenu = menu.submenus.find((submenu) => submenu.subMenuId === user.homePage);
      if (subMenu) {
        return { subMenuId: subMenu.subMenuId, url: subMenu.url };
      }
    }
    return null;
  };

  const navigate = useNavigate();

  const login = async (event) => {
    //@TODO loader
    event.preventDefault();
    if (loginData.userName.length < 1 || loginData.password.length < 1) {
      // @TODO talán a form mezőnek kellene visszaadni (formik)
      console.log('Üres adatok');
      return;
    }

    authService
      .login(loginData)
      .then((userData) => {
        let views = userData.views ? JSON.parse(userData.views) : {};
        if (!views.ganttFilter) {
          views.ganttFilter = { task: true, production: true };
        }
        userData.views = views;

        setUser(userData);

        Promise.all([appService.getMenuData(), appService.getParams()]).then(([menuData, paramsData]) => {
          setMenus(menuData);
          setPages({});
          setParam(paramsData);

          /*localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('loginStatus', false);*/
          const foundSubMenu = findSubMenu(userData, menuData);
          if (foundSubMenu) {
            navigate(`/app/${foundSubMenu.url}/${foundSubMenu.subMenuId}`);
          } else {
            navigate('/app');
          }
        });
      })
      .finally(() => {
        //@TODO loader
      });
  };
  return (
    <div className="flex h-screen">
      <div
        className={
          'flex flex-1 flex-col justify-center overflow-auto px-4 py-12 transition-colors duration-1000 sm:px-6 lg:flex-none lg:px-20 xl:px-24'
        }
      >
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src="/QUANTELLIGEN.png" alt="Logó" />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900"> Bejelentkezés</h2>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={login} className="space-y-6">
                <div>
                  <div className="mt-2">
                    <InputUI
                      type="text"
                      name="userName"
                      label="Felhasználónév"
                      variant="standard"
                      id="userName"
                      value={loginData.userName}
                      required
                      onChange={(e) => setLoginData({ ...loginData, userName: e.target.value })}
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-2">
                    <InputUI
                      label="Jelszó"
                      variant="standard"
                      type="password"
                      name="password"
                      id="password"
                      value={loginData.password}
                      required
                      onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                    />
                  </div>
                </div>
                {/* @TODO ez mi a szar? */}
                {showMessage && <p className="text-red-400"> Hibás dolgozó azonosító!</p>}
                <ButtonUI
                  text="Bejelentkezés"
                  type="submit"
                  className="flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:enabled:bg-indigo-500 disabled:cursor-not-allowed disabled:opacity-50"
                ></ButtonUI>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
