import React from 'react';
import { DialogUI } from '../Interface';
import { ModifySimpleTask } from '../SimpleTaskDialog/ModifySimpleTask/ModifySimpleTask';

const ModifyTaskDialog = ({ open, handleClose, task, modify }) => {
  const { taskModified, setTaskModified } = modify;
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Feladat módosítása"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <ModifySimpleTask
          handleClose={handleClose}
          taskModified={taskModified}
          setTaskModified={setTaskModified}
          task={task}
        />
      </div>
    </DialogUI>
  );
};

export default ModifyTaskDialog;
