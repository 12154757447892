import { useState, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TabsUI from '../Interface/TabsUI';

import TodoContent from './TodoContent';
import simpletaskService from '../../service/simpletask.service';

const MyTodoTable = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        simpletaskService
          .getMyTodoPriority()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        simpletaskService
          .getMyTodoStatus()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        setPriority(resp[0]);
        setStatus(resp[1]);
      })
      .finally(() => {
        //hideLoader()
      });
  }, []);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const TodoContentMemo = useMemo(() => (p) => <TodoContent activeTab={activeTab} {...p} />, [activeTab]);

  const todoTabs = [
    {
      id: 0,
      name: 'Saját',
      content: <TodoContentMemo type={'own'} tabId={0} />,
    },
    {
      id: 1,
      name: 'Nyitott',
      content: <TodoContentMemo type={'opened'} tabId={1} />,
    },
    {
      id: 2,
      name: 'Összes',
      content: <TodoContentMemo type={'all'} tabId={2} />,
    },
  ];

  return (
    <div className="fixed top-16 inset-0 gap-4 bg-gray-100 p-4 h-full overflow-x-auto">
      <div>
        <div className="flex justify-center w-full mr-auto ml-auto">
          <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
            {todoTabs.map((tab, index) => (
              <div key={index} name={tab.name}>
                {tab.content}
              </div>
            ))}
          </TabsUI>
        </div>
      </div>
    </div>
  );
};

export default MyTodoTable;
