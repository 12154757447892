import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import 'dayjs/locale/hu';

const TimePickerUi = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <TimePicker
        slotProps={{
          textField: {
            variant: 'standard',
            helperText: props.validationMessage,
          },

          field: { clearable: true, onClear: () => {} },
        }}
        sx={{ width: '100%' }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default TimePickerUi;
