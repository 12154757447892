import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import sapResourcePlannerService from '../../service/sapResourcePlanner.service';
import SapProductOrderDialog from './SapProductOrderDialog';
import { ColorPicker } from '../../config/ColorPicker';
import EventChip from '../TaskGantt/EventChip';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const SapResourcePlanner = () => {
  const { getParam } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], events: [], backgroundEvents: [] });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [task, setTask] = useState([]);
  const [dateRange, setDateRange] = useState({});
  const [userTimezone, setUserTimezone] = useState(null);

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');

  const slotIntervals = {
    halfHour: { hours: 0.5 },
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarHourViews = {
    resourceTimelineDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [
        { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [
        { day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setUserTimezone(dayjs.tz.guess());
    setTimeInterval(slotIntervals.halfHour);
    setCalendarViews(calendarHourViews);

    // @TODO promiseArray
    getResources();
    getParam();
  }, []);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      // @TODO promiseArray
      getProductionOrders();
      getActiveTimes();
    }
  }, [dateRange]);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  const getResources = () => {
    showLoader();
    sapResourcePlannerService
      .getResources()
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getActiveTimes = () => {
    showLoader();
    sapResourcePlannerService
      .getActiveTimeByRange(dateRange)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, backgroundEvents: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductionOrders = () => {
    showLoader();
    sapResourcePlannerService
      .getProductionOrder(dateRange)
      .then((data) => {
        const events = data.map((po) => {
          return { ...po, ...getColor(po.U_QNT_PLANSTATUS === 'KEZDO' ? 'blue' : 'green') };
        });

        setTasks((prevState) => ({ ...prevState, events }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductionOrderById = (id) => {
    showLoader();
    sapResourcePlannerService
      .getProductionOrderById(id)
      .then((data) => {
        setTask(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColor = (colorName) => {
    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj ? colorObj.colorBgCode : '#fff',
      textColor: colorObj ? colorObj.textColor : '#000',
    };
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.startDate ||
      !dateRange.endDate ||
      !startDate.isSame(dateRange.startDate) ||
      !endDate.isSame(dateRange.endDate)
    ) {
      setDateRange({ startDate, endDate });
    }

    if (selectedView !== dateInfo.view.type) setSelectedView(dateInfo.view.type);
  };

  const updateEvent = (resizeInfo) => {
    const { event, revert } = resizeInfo;

    const newEndDate = dayjs(event.end).toISOString();
    const newStartDate = dayjs(event.start).toISOString();
    const resource = event.getResources()[0].id;

    const updatedEventData = {
      startDate: newStartDate,
      endDate: newEndDate,
      machineId: resource,
    };

    const simpleTaskId = event.id;

    sapResourcePlannerService
      .updateProductionOrder(updatedEventData, simpleTaskId)
      .then(() => {
        addNotification({
          content: 'Sikeres módosítás!',
          type: NotificationType.SUCCESS,
        });
        getProductionOrders();
      })
      .catch(() => {
        revert();
      })
      .finally(() => {
        hideLoader();
      });
  };

  const timeIntervalButtons = {
    halfHour: {
      text: 'Fél óra',
      click: () => {
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);
      },
    },
    hour: {
      text: 'Óra',
      click: () => {
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);
      },
    },
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'halfHour,hour,day';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineWeek':
        retVal = 'halfHour,hour,day,week';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineMonth':
        retVal = 'hour,day,week,month';
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  return (
    <div>
      <FullCalendar
        customButtons={timeIntervalButtons}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineDay"
        resources={tasks.resources}
        locales={[huLocale]}
        locale="hu"
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        datesSet={handleDatesSet}
        events={[...tasks.events, ...tasks.backgroundEvents]}
        editable={true}
        nowIndicator={true}
        eventResize={updateEvent}
        eventDrop={updateEvent}
        eventClick={(clickInfo) => {
          if (clickInfo.event.display !== 'background') {
            const taskId = clickInfo.event.id;
            getProductionOrderById(taskId);
          }
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        timeZone={userTimezone}
        resourceAreaWidth="15%"
        resourceAreaHeaderContent="Erőforrás"
        scrollTimeReset={false}
      />
      <SapProductOrderDialog
        open={dialogOpen}
        task={task}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />
    </div>
  );
};

export default SapResourcePlanner;
