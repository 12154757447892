import { useEffect, useState } from 'react';

import appService from '../../service/app.service';
import RoleModify from './RoleModify';

export default function RoleInformation(props) {
  const [role, setRole] = useState([]);
  const [roleSubMenuPermissions, setRoleSubMenuPermissions] = useState([]);
  const [allSubMenus, setAllSubMenus] = useState([]);
  const [roleModalShow, setRoleModalShow] = useState(false);
  const [roleHasPermission, setRoleHasPermission] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);

  const fetchData = async () => {
    if (!props.roleId) {
      return;
    }
    appService
      .getRoleInformation(props.roleId)
      .then((data) => {
        setRole(data);
        fetchRoleSubMenuPermissions(data[0]?.roleId);
        fetchAllSubMenus();
      })
      .finally(() => {});
  };

  const fetchRoleSubMenuPermissions = async () => {
    appService
      .getUserSubMenuPermissions(props.roleId)
      .then((data) => {
        setRoleSubMenuPermissions(data);
      })
      .finally(() => {});
  };

  const fetchAllSubMenus = async () => {
    appService
      .getAllSubMenus()
      .then((data) => {
        setAllSubMenus(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [props.roleId]);

  useEffect(() => {
    fetchData();
  }, [refreshPage]);

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 h-full overflow-x-auto">
        <div className="mt-4">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Jogosultságok</h3>
          <table className="mt-2 min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Menüpont
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Jogosult
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody>
              {allSubMenus.map((subMenu, index) => (
                <tr key={index} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {subMenu.subMenuName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {roleSubMenuPermissions.find((permission) =>
                      permission.roleSubmenuPermission.some(
                        (rolePerm) => rolePerm.roleId === props.roleId && permission.subMenuName === subMenu.subMenuName
                      )
                    )
                      ? 'Jogosult'
                      : 'Nem jogosult'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      onClick={() => {
                        setRoleModalShow(true);
                        setRoleHasPermission(
                          roleSubMenuPermissions.find((permission) =>
                            permission.roleSubmenuPermission.some(
                              (rolePerm) =>
                                rolePerm.roleId === props.roleId && permission.subMenuName === subMenu.subMenuName
                            )
                          )
                            ? true
                            : false
                        );
                        setSelectedSubMenu(subMenu.subMenuId);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Szerkesztés
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <RoleModify
        show={roleModalShow}
        onHide={() => setRoleModalShow(false)}
        hasPermission={roleHasPermission}
        roleId={props.roleId}
        selectedSubMenu={selectedSubMenu}
        roleSubMenuPermissions={roleSubMenuPermissions}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        setRoleSubMenuPermissions={setRoleSubMenuPermissions}
      />
    </>
  );
}
