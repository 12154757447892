import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import operationService from '../../service/operation.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import FormFields from './FormFields';
import OperationReports from './OperationReports';

export const OperationForm = () => {
  const defaultValues = { operationName: '' };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});

  useEffect(() => {
    setInitValues(defaultValues);
  }, []);

  const validationSchema = Yup.object().shape({
    operationName: Yup.string().required(),
  });

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const { operationName } = selectedValues;

    setInitValues({
      operationName,
    });
  };

  const handleInsertObject = (values, resetForm) => {
    showLoader();
    const insertObject = { ...values };

    operationService
      .createOperation(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values, resetForm);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values, resetForm) => {
    const updData = {
      ...values,
    };

    operationService.updateOperation(updData, selectedValues.operationId).then((_operation) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      resetForm();
      setInitValues({});
      setSelectedValues(defaultValues);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    operationService.deleteOperation(selectedValues.operationId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, values, errors, resetForm }) => (
              <Form>
                <FormFields values={values} errors={errors} viewField={viewField} />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <OperationReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
