import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const FormFields = ({ selectedValues, values, validateField, setFieldValue, errors, submenu }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="simpleTaskName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Feladat rövid neve" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.simpleTaskName} />}
          InputLabelProps={{ shrink: values.simpleTaskName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="simpleTaskTypeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat típusa" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.simpleTaskTypeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('simpleTaskTypeId', newVal?.value ?? null).then(() => {
              validateField('simpleTaskTypeId');
            });
          }}
          InputLabelProps={{ shrink: values.simpleTaskTypeId !== '' }}
          selectedValue={values.simpleTaskTypeId}
          selectedLabelValue={selectedValues?.taskType?.simpleTaskTypeName}
          table={['typeitems', 'type']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'typeitems.typeId = type.typeId'}
          conditions={[`typeitems.archive = 'N'`, `type.subMenuId = ${submenu}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="priorId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Prioritás" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.priorId} />}
          onChange={(_e, newVal) => {
            setFieldValue('priorId', newVal?.value ?? null).then(() => {
              validateField('priorId');
            });
          }}
          InputLabelProps={{ shrink: values.priorId !== '' }}
          selectedValue={values.priorId}
          selectedLabelValue={selectedValues?.priority?.itemName}
          table={['prioritylistitems ', 'priority']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'prioritylistitems.priorityId = priority.priorityId'}
          conditions={[`priority.archive = 'N'`, `priority.subMenuId = ${submenu}`]}
        />
      </div>
    </div>
  );
};

export default FormFields;
