export const ColorPicker = [
  { colorCode: '#ffffff', colorBgCode: '#ffffff', colorName: 'white', textColor: '#667280' },
  { colorCode: '#1185f2', colorBgCode: '#dbeafe', colorName: 'blue', textColor: '#3b82f6' },
  { colorCode: '#00ffff', colorBgCode: '#acfffc', colorName: 'cyan', textColor: '#008B8B' },
  { colorCode: '#fca5a5', colorBgCode: '#fecdd3', colorName: 'red', textColor: '#ef4444' },
  { colorCode: '#4ade80', colorBgCode: '#bbf7d0', colorName: 'green', textColor: '#16a34a' },
  { colorCode: '#fde047', colorBgCode: '#fde68a', colorName: 'yellow', textColor: '#ca8a04' },
  { colorCode: '#fdba74', colorBgCode: '#fed7aa', colorName: 'orange', textColor: '#f97316' },
  { colorCode: '#7b2cbf', colorBgCode: '#cbc3e3', colorName: 'purple', textColor: '#301934' },
];
