import React from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const FormFields = ({ viewField, submenuId, selectedValues, values, validateField, setFieldValue, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-3 md:col-span-1">
        <Field
          type="text"
          name="workingNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Munkaszám" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.workingNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.workingNumber !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="text"
          name="customerOrdNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Vevői rendelés szám" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.customerOrdNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerOrdNumber !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          table={'project'}
          listType={{ id: 'projectId', name: 'projectName' }}
          conditions={[`project.archive = 'N'`]}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="text"
          name="articleNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikkszám" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.articleNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.articleNumber !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="text"
          name="articleName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikknév" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.articleName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.articleName !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="text"
          name="productionQuantity"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Gyártandó mennyiség" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.productionQuantity} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.productionQuantity !== '' }}
          onChange={(_e, newVal) => {
            setFieldValue('productionQuantity', newVal?.value ? parseFloat(newVal?.value) : null).then(() => {
              validateField('productionQuantity');
            });
          }}
        />
      </div>

      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="me"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="ME" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.me} />}
          onChange={(_e, newVal) => {
            setFieldValue('me', newVal?.value ?? null).then(() => {
              validateField('me');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.me !== '' }}
          selectedValue={values.me}
          selectedLabelValue={selectedValues?.me}
          table={'quantityunit'}
          listType={{ id: 'quantityUnitId', name: 'quantityUnitName' }}
        />
      </div>

      <div className="col-span-3 md:col-span-1 ">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="GY. tervezett kezdete" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="GY. tervezett vége" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gyártás státusza" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="priorityId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gyártás priorítása" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.priorityId} />}
          onChange={(_e, newVal) => {
            setFieldValue('priorityId', newVal?.value ?? null).then(() => {
              validateField('priorityId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.priorityId !== '' }}
          selectedValue={values.priorityId}
          selectedLabelValue={selectedValues?.priority?.itemName}
          table={['priority', 'priorityListItems']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'priorityListItems.priorityId = priority.priorityId'}
          conditions={[`priorityListItems.archive = 'N'`, `priority.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="companyId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Vevő neve" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.companyId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyId', newVal?.value ?? null).then(() => {
              validateField('companyId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyId !== '' }}
          selectedValue={values.companyId}
          selectedLabelValue={selectedValues?.company?.companyName}
          table={'company'}
          listType={{ id: 'companyId', name: 'companyName' }}
          conditions={`company.archive='N'`}
        />
      </div>
      <div className="col-span-3 md:col-span-1">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Tulajdonos" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          disabled
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.userName}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
    </div>
  );
};

export default FormFields;
