import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import taskGanttService from '../../service/taskGantt.service';
import simpletaskService from '../../service/simpletask.service';
import { ColorPicker } from '../../config/ColorPicker';
import TodoDialog from '../MyTodo/TodoDialog';
import EventChip from './EventChip';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import ProductDialog from './ProductDialog';
import productionService from '../../service/production.service';
import useView from '../../hooks/useView';
import GanttFilter from './GanttFilter';
import CreateTaskDialog from './CreateTaskDialog';
import ModifyTaskDialog from './ModifyTaskDialog';

const TaskGanttForm = () => {
  const { getParam, params } = useParam();
  const ganttFilter = useView('ganttFilter');
  const checkboxFiltersRef = useRef(null);
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], taskEvents: [], prodEvents: [], backgroundEvents: [] });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
  const [task, setTask] = useState({});
  const [production, setProduction] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [taskCreated, setTaskCreated] = useState(false);
  const [taskModified, setTaskModified] = useState(false);
  const [userTimezone, setUserTimezone] = useState(null);
  const [filterFormState, setFilterFormState] = useState({
    productionId: null,
    operationId: null,
  });

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');

  const slotIntervals = {
    halfHour: { hours: 0.5 },
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarHourViews = {
    resourceTimelineDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [
        { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [
        { day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  useEffect(() => {
    const toolbarElement = document.querySelector('.fc-toolbar-chunk');
    if (toolbarElement && checkboxFiltersRef.current) {
      toolbarElement.appendChild(checkboxFiltersRef.current);
    }
  }, []);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setUserTimezone(dayjs.tz.guess());
    setTimeInterval(slotIntervals.halfHour);
    setCalendarViews(calendarHourViews);

    // @TODO promiseArray
    getMachines();
    getParam();
  }, []);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      if (ganttFilter?.production) {
        getProductions();
      } else {
        setTasks((prevTasks) => ({
          ...prevTasks,
          prodEvents: [],
        }));
      }

      if (ganttFilter?.task) {
        getTasks();
      } else {
        setTasks((prevTasks) => ({
          ...prevTasks,
          taskEvents: [],
        }));
      }
    }
  }, [dateRange, ganttFilter]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getActiveTimes();
    }
  }, [dateRange]);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (ganttFilter?.production) {
      getProductions();
    }
  }, [filterFormState]);

  useEffect(() => {
    if ((taskCreated || taskModified) && ganttFilter?.task) {
      getTasks();
      setTaskCreated(false);
      setTaskModified(false);
    }
  }, [taskCreated, taskModified]);

  const getMachines = () => {
    showLoader();
    taskGanttService
      .getMachines()
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getActiveTimes = () => {
    //showLoader();
    taskGanttService
      .getActiveTimeByRange(dateRange)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, backgroundEvents: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductions = () => {
    //showLoader();
    const { startDate, endDate } = dateRange;
    const { productionId, operationId } = filterFormState;
    taskGanttService
      .getProductions({ startDate, endDate, productionId, operationId })
      .then((data) => {
        const prodEvents = data.flatMap((task) => {
          const { productionOperation, status } = task;
          if (!productionOperation || productionOperation.length === 0) return [];

          return productionOperation.map((operation) => ({
            id: operation.prodOperationId,
            title: operation.operation?.operationName,
            start: operation.startDate,
            end: operation.endDate,
            ...getColor(status?.color),
            resourceId: `M${operation.machineId}`,
            eventData: { ...task, type: 'PRODUCTION' },
          }));
        });
        setTasks((prevState) => ({ ...prevState, prodEvents }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getTasks = () => {
    //showLoader();
    taskGanttService
      .getTask(dateRange)
      .then((data) => {
        const taskEvents = data.map((task) => {
          //@TODO végül miben maradtunk? a BE adja jól vissza, vagy megtartjuk így -- megtartjuk így
          const { machine, simpleTaskId, simpleTaskName, startDate, endDate, responsiblePerson, status } = task;
          if (!machine) return null;

          return {
            id: simpleTaskId,
            title: simpleTaskName,
            start: startDate,
            responsiblePerson: responsiblePerson?.userName,
            end: endDate,
            resourceId: `M${machine.machineId}`,
            ...getColor(status?.color),
            eventData: { ...task, type: 'TASK' },
          };
        });
        setTasks((prevState) => ({ ...prevState, taskEvents }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getTaskById = (simpleTaskId) => {
    showLoader();
    simpletaskService
      .getTaskById(simpleTaskId)
      .then((data) => {
        setTask(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductById = (id) => {
    showLoader();
    productionService
      .getProductionById(id)
      .then((data) => {
        setProduction(data);
        setProductDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColor = (colorName) => {
    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj ? colorObj.colorBgCode : '#fff',
      textColor: colorObj ? colorObj.textColor : '#000',
    };
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.startDate ||
      !dateRange.endDate ||
      !startDate.isSame(dateRange.startDate) ||
      !endDate.isSame(dateRange.endDate)
    ) {
      setDateRange({ startDate, endDate });
    }

    if (selectedView !== dateInfo.view.type) setSelectedView(dateInfo.view.type);
  };

  const updateEvent = (resizeInfo) => {
    const { event, revert } = resizeInfo;
    const { eventData } = event.extendedProps;
    const resource = event.getResources()[0].id;

    const isMachine = resource.startsWith('M');

    if (!isMachine) {
      addNotification({
        content: 'Az esemény nem helyezhető csoportra!',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    const startDate = dayjs(event.start).toISOString();
    const endDate = event.end ? dayjs(event.end).toISOString() : null;

    const machineId = parseInt(resource.substring(1));

    const updatedEventData = {
      machineId,
      startDate,
      endDate,
    };

    //showLoader();

    if (eventData.type === 'TASK') {
      const simpleTaskId = event.id;

      taskGanttService
        .updateTask(updatedEventData, simpleTaskId)
        .then((data) => {
          addNotification({
            content: 'Sikeres módosítás!',
            type: NotificationType.SUCCESS,
          });
          setTasks((prevTasks) => ({
            ...prevTasks,
            taskEvents: prevTasks.taskEvents.map((taskEvent) => (taskEvent.id === data.id ? data : taskEvent)),
          }));
        })
        .catch(() => {
          revert();
        })
        .finally(() => {
          hideLoader();
        });
    } else if (eventData.type === 'PRODUCTION') {
      const prodOperationId = eventData.productionOperation[0].prodOperationId;

      taskGanttService
        .updateProduction(updatedEventData, prodOperationId)
        .then((data) => {
          addNotification({
            content: 'Sikeres módosítás!',
            type: NotificationType.SUCCESS,
          });
          setTasks((prevTasks) => ({
            ...prevTasks,
            prodEvents: prevTasks.prodEvents.map((prodEvent) => (prodEvent.id === data.id ? data : prodEvent)),
          }));
        })
        .catch(() => {
          revert();
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  const timeIntervalButtons = {
    halfHour: {
      text: 'Fél óra',
      click: () => {
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);
      },
    },
    hour: {
      text: 'Óra',
      click: () => {
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);
      },
    },
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'halfHour,hour,day';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineWeek':
        retVal = 'halfHour,hour,day,week';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineMonth':
        retVal = 'hour,day,week,month';
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  return (
    <div>
      <div ref={checkboxFiltersRef}>
        <GanttFilter
          filter={{ filterFormState, setFilterFormState }}
          ganttFilter={ganttFilter}
          setCreateTaskDialogOpen={setCreateTaskDialogOpen}
        />
      </div>
      <FullCalendar
        customButtons={timeIntervalButtons}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineDay"
        resources={tasks.resources}
        resourceOrder="visOrder"
        locales={[huLocale]}
        locale="hu"
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        datesSet={handleDatesSet}
        events={[...tasks.taskEvents, ...tasks.backgroundEvents, ...tasks.prodEvents]}
        editable={true}
        nowIndicator={true}
        eventResize={updateEvent}
        eventDrop={updateEvent}
        eventClick={(clickInfo) => {
          if (clickInfo.event.display !== 'background') {
            const id = clickInfo.event.id;
            const eventType = clickInfo.event._def.extendedProps.eventData.type;

            if (eventType === 'PRODUCTION') {
              getProductById(id);
            } else {
              getTaskById(id);
            }
          }
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        timeZone={userTimezone}
        resourceAreaWidth="15%"
        resourceAreaHeaderContent="Erőforrás"
        scrollTimeReset={false}
        resourceLabelClassNames={(data) => {
          if (!data.resource._resource.parentId) {
            return ['fc-res-group'];
          }
        }}
      />
      {params.TASKGANTT_MODIFY_TASK === 'y' ? (
        <ModifyTaskDialog
          open={dialogOpen}
          modify={{ taskModified, setTaskModified }}
          task={task}
          handleClose={() => {
            setDialogOpen(false);
          }}
        />
      ) : (
        <TodoDialog
          open={dialogOpen}
          task={task}
          handleClose={() => {
            setDialogOpen(false);
          }}
        />
      )}
      <ProductDialog
        open={productDialogOpen}
        production={production}
        handleClose={() => {
          setProductDialogOpen(false);
        }}
      ></ProductDialog>
      <CreateTaskDialog
        setTaskCreated={setTaskCreated}
        create={{ taskCreated, setTaskCreated }}
        open={createTaskDialogOpen}
        handleClose={() => {
          setCreateTaskDialogOpen(false);
        }}
      />
    </div>
  );
};

export default TaskGanttForm;
