import React from 'react';

export const RejectReportTable = () => {
  return (
    <>
      <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
              <div className="sticky top-0 bg-white">
                <div className="pt-2 "></div>
              </div>
              <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
                <div className="z-40 min-w-full h-[100%]">
                  <h1>Selejt lejelentés</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
