import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'project';

const projectService = {
  getProjects: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projects`,
      data,
    });
  },

  createProject: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProject: (data, projectId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${projectId}`,
      data,
    });
  },

  deleteProject: (projectId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${projectId}`,
    });
  },
};

export default projectService;
