import dayjs from 'dayjs';
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography } from '@material-tailwind/react';

const SapProductOrderDialog = (props) => {
  const { open, handleClose, task } = props;

  const {
    id,
    DocEntry,
    DocNum,
    DueDate,
    U_QNT_RAJZSZAM,
    ProdName,
    FinishPlannedQty,
    Project,
    LineNum,
    ItemCode,
    PlannedQty,
    CalcPlannedQty,
    U_QNT_GEP,
    StartDate,
    U_QNT_STARTTIME,
    EndDate,
    U_QNT_ENDTIME,
    COMBINED_START,
    COMBINED_END,
    U_QNT_PLANSTATUS,
  } = task;

  return (
    <Dialog open={open} handler={handleClose}>
      <DialogHeader className="bg-labelColor">
        <h2 className="bg-labelColor text-white py-1 px-3 rounded">
          GYU szám - {DocNum} Sor száma:{LineNum}
        </h2>
      </DialogHeader>
      <DialogBody className="h-96 overflow-y-auto md:h-auto">
        <div className="block grid-cols-4 gap-x-4 gap-y-2 mx-4 md:grid">
          <Typography className="mb-3 ">
            <h4 className="font-semibold">Művelet</h4>
            <p className="text-gray-700">{ItemCode}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Tervezett idő</h4>
            <p className="text-gray-700">{PlannedQty}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Esedékesség</h4>
            <p className="text-gray-700">{dayjs(DueDate).format('YYYY.MM.DD.')}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Késztermék</h4>
            <p className="text-gray-800">{U_QNT_RAJZSZAM}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Megnevezés</h4>
            <p className="text-gray-800">{ProdName}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Késztermék mennyisége</h4>
            <p className="text-gray-800">{FinishPlannedQty}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Kezdő dátum, időpont</h4>
            <p className="text-gray-700">{dayjs(COMBINED_START).format('YYYY.MM.DD. HH:mm')}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Záró dátum, időpont</h4>
            <p className="text-gray-700">{dayjs([COMBINED_END]).format('YYYY.MM.DD. HH:mm')}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Tervezés státusza</h4>
            <p className="text-gray-800">{U_QNT_PLANSTATUS}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Projekt</h4>
            <p className="text-gray-800">{Project}</p>
          </Typography>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="blue-gray" onClick={handleClose}>
          <span>Bezárás</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SapProductOrderDialog;
