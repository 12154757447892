import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';
import SelectUI from '../Interface/SelectUI';

const FormFields = ({ submenu, selectedTask, values, validateField, setFieldValue, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4">
        <Field
          type="autocomplete"
          name="simpleTaskId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.simpleTaskId} />}
          onChange={(_e, newVal) => {
            setFieldValue('simpleTaskId', newVal?.value ?? null).then(() => {
              validateField('simpleTaskId');
            });
          }}
          InputLabelProps={{ shrink: values.simpleTaskId !== '' }}
          selectedValue={values.simpleTaskId}
          selectedLabelValue={selectedTask?.taskType?.simpleTaskId}
          table={['simpleTask']}
          listType={{ id: 'simpleTaskId', name: 'simpleTaskName' }}
          joins={'simpleTask.simpleTaskId'}
          conditions={[`simpleTask.archive = 'N'`]}
          getOptionLabel={(option) => {
            const paddedId = option.value.toString().padStart(5, '0');
            return `${option.label} (F-${paddedId})`;
          }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="timeSpent"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Ráfordított idő" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.timeSpent} />}
          InputLabelProps={{ shrink: values.timeSpent !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          name="me"
          render={({ field }) => (
            <SelectUI
              {...field}
              label={<FormLabelUI text="ME" />}
              defaultValue="h"
              onChange={(selectedValue) => {
                setFieldValue('me', selectedValue);
                validateField('me');
              }}
              value={['h', 'm']}
            />
          )}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="autocomplete"
          name="typeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat típusa" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.typeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('typeId', newVal?.value ?? null).then(() => {
              validateField('typeId');
            });
          }}
          InputLabelProps={{ shrink: values.typeId !== '' }}
          selectedValue={values.typeId}
          selectedLabelValue={selectedTask?.taskType?.typeId}
          table={['typeitems', 'type']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'typeitems.typeId = type.typeId'}
          conditions={[`typeitems.archive = 'N'`, `type.subMenuId = ${submenu}`]}
        />
      </div>
    </div>
  );
};

export default FormFields;
