import { useEffect, useState } from 'react';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
} from '@mui/x-data-grid';

import { AutoCompleteSelectUI, ButtonUI, DataGridUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';
import React from 'react';
import productionService from '../../service/production.service';
import operationService from '../../service/operation.service';
import machineService from '../../service/machine.service';
import dayjs from 'dayjs';

function EditToolbar(props) {
  const { setRows, setRowModesModel, productionId } = props;

  const handleClick = () => {
    const newEmptyRow = {
      timeSpent: 1,
      startDate: dayjs(new Date()),
    };

    productionService.createProductionOperation(newEmptyRow, productionId).then((data) => {
      setRows((oldRows) => [data.prodOperations.find((row) => row.prodOperationId === data.newRowId), ...oldRows]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [data.newRowId]: { mode: GridRowModes.Edit, fieldToFocus: 'operationId' },
      }));
    });
  };

  return (
    <GridToolbarContainer>
      <ButtonUI className="bg-success mb-1" text="Művelet hozzáadása" onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const FormTable = ({ productionList, productionId, setRefetchTable }) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [operationList, setOperationList] = useState([]);
  const [machineList, setMachineList] = useState([]);

  useEffect(() => {
    setRows(productionList);
  }, [productionList]);

  useEffect(() => {
    getOperations();
    getMachines();
  }, []);

  const getOperations = () => {
    operationService.getOperations().then((data) => {
      setOperationList(data);
    });
  };

  const getMachines = () => {
    machineService.getSingleMachines().then((data) => {
      setMachineList(data);
    });
  };

  const handleUpdateRow = (updatedRow, prodOperationId) => {
    productionService.updateProductionOperation(updatedRow, prodOperationId).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (prodOperationId) => {
    productionService.deleteProductionOperation(prodOperationId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (prodOperationId) => () => {
    setRows(rows.filter((row) => row.prodOperationId !== prodOperationId));
    handleDeleteRow(prodOperationId);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.prodOperationId === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const updatedRows = rows.map((row) => (row.prodOperationId === newRow.prodOperationId ? updatedRow : row));

    updatedRows.sort((a, b) => a.prodOperationId - b.prodOperationId);

    setRows(updatedRows);

    handleUpdateRow(updatedRow, newRow.prodOperationId);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getMachineNameById = (id) => {
    const machine = machineList.find((item) => item.machineId === id);
    return machine ? machine.machineName : id;
  };

  const getOperationNameById = (id) => {
    const operation = operationList.find((item) => item.operationId === id);
    return operation ? operation.operationName : id;
  };

  const columns = dataGridColumns([
    {
      field: 'label',
      headerName: 'Művelet azon.',
      flex: 1,
    },
    {
      field: 'operationId',
      headerName: 'Művelet',
      flex: 1,
      editable: true,
      renderCell: (params) => getOperationNameById(params.value),
      renderEditCell: (params) => (
        <AutoCompleteSelectUI
          options={operationList.map((operation) => operation.operationId)}
          getOptionLabel={(option) => {
            const operation = operationList.find((item) => item.operationId === option);
            return operation ? operation.operationName : '';
          }}
          value={params.row.operationId}
          onChange={(_event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue ? newValue : null,
            });
          }}
        />
      ),
    },

    {
      field: 'machineId',
      headerName: 'Erőforrás',
      flex: 1,
      editable: true,
      renderCell: (params) => getMachineNameById(params.value),
      renderEditCell: (params) => (
        <AutoCompleteSelectUI
          options={machineList.map((machine) => machine.machineId)}
          getOptionLabel={(option) => {
            const machine = machineList.find((item) => item.machineId === option);
            return machine ? machine.machineName : '';
          }}
          value={params.row.machineId}
          onChange={(_event, newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: newValue ? newValue : null,
            });
          }}
        />
      ),
    },
    {
      field: 'startDate',
      headerName: 'Gy. terv. kezdete',
      flex: 1,
      editable: true,
      type: 'dateTime',
      valueGetter: (params) => {
        const value = params.value;
        return value ? new Date(value) : null;
      },
    },

    {
      field: 'timeSpent',
      headerName: 'Ráfordítás',
      flex: 1,
      editable: true,
      type: 'number',

      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            min: 1,
          }}
        />
      ),
    },
    {
      field: 'description',
      headerName: 'Megjegyzés',
      flex: 1,
      editable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Szerk.',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ];
      },
    },
  ]);

  return (
    <div className="px-4">
      <div className="sm:flex sm:items-center"></div>
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <DataGridUI
                sx={{ height: 440, width: '100%' }}
                columns={columns}
                rows={rows}
                getRowId={(row) => row.prodOperationId}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel, productionId, rows, setRefetchTable },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormTable;
