import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';

import simpletaskService from '../../../service/simpletask.service';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import useUsers from '../../../context/UsersContext';
import useMenus from '../../../context/MenuContext';

import FormFields from './FormFields';
import FormTabs from './FormTabs';
import { FormButtonPanelUI, FormHeaderUI } from '../../Interface';
import Yup from '../../../utils/yup';

export const ModifySimpleTask = (props) => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const [fileList, setFileList] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [submenu, setSubmenu] = useState(null);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [viewField, setViewField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    getUser();
    getMenus();

    const def = {
      simpleTaskName: props.task.simpleTaskName,
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()).add(1, 'hour'),
      duration: props.task.duration,
      preparedness: props.task.preparedness,
      machineId: props.task.machineId,
      projectId: props.task.projectId,
      simpleTaskTypeId: props.task.simpleTaskTypeId,
      userId: null,
      resposiblePersonId: props.task.resposiblePersonId,
      statusId: props.task.statusId,
      priorId: props.task.priorId,
      simpleTaskDescription: props.task.simpleTaskDescription,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setFileList(props.task?.simpleTaskAttachment ?? []);
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    preparedness: Yup.number().nullable().positive(),
    machineId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    simpleTaskTypeId: Yup.string().required(),
    userId: Yup.string().required(),
    resposiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().required(),
    priorId: Yup.string().required(),
    simpleTaskDescription: Yup.string(),
  });

  const valuesToForm = () => {
    const {
      simpleTaskName,
      startDate,
      endDate,
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    } = selectedValues;

    setInitValues({
      simpleTaskName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    simpletaskService.updateSimpleTask(updData, props.task.simpleTaskId).then((_simpleTask) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setEditButtonvisible(true);
      setViewField(true);
      props.setTaskModified(true);
      props.handleClose();
    });
  };

  return (
    <div>
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg -mt-3">
          <FormHeaderUI
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField}
          />
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={updateObject}
            enableReinitialize
          >
            {({ setFieldValue, errors, validateField, values }) => (
              <Form>
                <FormFields
                  submenuId={submenu}
                  viewField={viewField}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                <FormTabs
                  task={props.task}
                  values={values}
                  disabled={viewField}
                  errors={errors}
                  simpleTaskId={props.task.simpleTaskId}
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  formId={'simpleTaskDescription'}
                  value={initValues.simpleTaskDescription}
                />

                {!editButtonVisible && <FormButtonPanelUI />}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
