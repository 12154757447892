import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';

import simpletaskService from '../../../service/simpletask.service';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import useUsers from '../../../context/UsersContext';
import useMenus from '../../../context/MenuContext';

import FormFields from './FormFields';
import FormTabs from './FormTabs';
import { FormButtonPanelUI } from '../../Interface';
import Yup from '../../../utils/yup';
import masterDataService from '../../../service/masterData.service';

export const CreateSimpleTask = (props) => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const [fileList, setFileList] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [submenu, setSubmenu] = useState(null);

  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    getUser();
    getMenus();

    const def = {
      simpleTaskName: '',
      startDate: dayjs(new Date()),
      endDate: dayjs(new Date()).add(1, 'hour'),
      duration: 1,
      preparedness: '',
      machineId: null,
      projectId: null,
      simpleTaskTypeId: null,
      userId: null,
      resposiblePersonId: null,
      statusId: null,
      priorId: null,
      simpleTaskDescription: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          simpleTaskTypeId: autocompleteData?.type?.defaultValue ?? null,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setFileList(selectedValues?.simpleTaskAttachment ?? []);
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          priority: resp[2],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    duration: Yup.number().positive(),
    preparedness: Yup.number().nullable().positive(),
    machineId: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    simpleTaskTypeId: Yup.string().required(),
    userId: Yup.string().required(),
    resposiblePersonId: Yup.string().nullable(),
    statusId: Yup.string().required(),
    priorId: Yup.string().required(),
    simpleTaskDescription: Yup.string(),
  });

  const valuesToForm = () => {
    const {
      simpleTaskName,
      startDate,
      endDate,
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    } = selectedValues;

    setInitValues({
      simpleTaskName,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      duration,
      preparedness,
      machineId,
      projectId,
      simpleTaskTypeId,
      userId,
      resposiblePersonId,
      statusId,
      priorId,
      simpleTaskDescription,
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
      duration: parseFloat(values.duration),
      preparedness: parseFloat(values.preparedness),
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
    };

    simpletaskService
      .createSimpleTask(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        props.setTaskCreated(true);
        props.handleClose();
      })

      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <div>
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleInsertObject}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField }) => (
              <Form>
                <FormFields
                  submenuId={submenu}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                <FormTabs
                  values={values}
                  errors={errors}
                  simpleTaskId={selectedValues?.simpleTaskId}
                  fileList={fileList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  formId={'simpleTaskDescription'}
                  value={initValues.simpleTaskDescription}
                />

                <FormButtonPanelUI />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
