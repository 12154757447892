import React, { useState } from 'react';
import { Field } from 'formik';
import DragAndDrop from '../ui/DragAndDrop';
import { TabsUI, FormLabelUI, FormErrorUI, InputUI } from '../Interface';

const DescriptionPanel = (props) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div>
          <Field
            type="text"
            name="description"
            as={InputUI}
            className={`${props.disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Megjegyzés" />}
            variant="standard"
            validationMessage={<FormErrorUI message={props.errors.description} />}
            disabled={props.disabled}
            InputLabelProps={{ shrink: props.values.description !== '' }}
            multiline
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

const FormTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Megjegyzés',
      content: <DescriptionPanel {...props} />,
    },
    {
      id: 1,
      name: 'Csatolmányok',
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-10">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default FormTabs;
