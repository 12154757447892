import React, { createContext, useContext, useReducer } from 'react';
import { menuReducer } from '../reducer/menuReducer';

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(menuReducer, {});

  const setMenus = (menus) => {
    return dispatch({ type: 'SET', payload: menus });
  };

  const getMenus = () => {
    return dispatch({ type: 'GET' });
  };

  const getMenuSubMenuId = (url) => {
    const menus = Object.values(state);
    if (!menus || !Array.isArray(menus)) {
      return null;
    }

    const allSubmenus = menus.reduce((acc, menu) => {
      if (menu.submenus) {
        return [...acc, ...menu.submenus];
      }
      return acc;
    }, []);

    const subMenu = allSubmenus.find((submenu) => submenu.url === url);

    return subMenu?.subMenuId ?? null;
  };

  const value = {
    menus: state,
    setMenus,
    getMenus,
    getMenuSubMenuId,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

const useMenus = () => {
  return useContext(MenuContext);
};

export default useMenus;
