import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'gantt/sapresourceplanner';

const sapResourcePlannerService = {
  getProductionOrder: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/prodorder`,
      data,
    });
  },

  getResources: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/resources`,
    });
  },

  updateProductionOrder: (data, docEntry) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/prodorder/${docEntry}`,
      data,
    });
  },

  getProductionOrderById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/prodorder/${id}`,
    });
  },

  getActiveTimeByRange: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/activeTimes`,
      data,
    });
  },
};
export default sapResourcePlannerService;
