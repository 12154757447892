import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import projectService from '../../service/project.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import ProjectReports from './ProjectReports';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import { FormFields } from './FormFields';
import { useParams } from 'react-router-dom';

export const ProjectForm = () => {
  const isAuthenticated = localStorage.getItem('user');
  const userObj = JSON.parse(isAuthenticated);

  let { id } = useParams();

  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [owner, setOwner] = useState(userObj.userId);

  const [defaultValues, setDefaultValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});

  useEffect(() => {
    setOwner(userObj);

    const def = {
      projectId: null,
      projectName: '',
      companyId: null,
      statusId: null,
      projectStartDate: dayjs(new Date()),
      projectEndDate: dayjs(new Date()),
      projectPrepare: '',
      userId: owner, //@TODO legyeb disabled szerintem? Kérdezzük meg Attilát
      projectDescription: '',
      projectGroupId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: owner.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: owner,
      };
    });
  }, [owner]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().nullable(),
    projectName: Yup.string().required(),
    companyId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    projectStartDate: Yup.string().required(),
    projectEndDate: Yup.string().required(),
    projectPrepare: Yup.number().nullable().positive(),
    userId: Yup.string().required(),
    projectDescription: Yup.string(),
    projectGroupId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate,
      projectEndDate,
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
    } = selectedValues;

    setInitValues({
      projectId,
      projectName,
      companyId,
      statusId,
      projectStartDate: dayjs(projectStartDate),
      projectEndDate: dayjs(projectEndDate),
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      projectPrepare: parseFloat(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
    };

    projectService.updateProject(updData, selectedValues.projectId).then((_project) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setRefetchTable(true);
      setSelectedValues(defaultValues);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      projectPrepare: parseFloat(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
    };

    projectService
      .createProject(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues(defaultValues);
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    projectService.deleteProject(selectedValues.projectId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ isSubmitting, values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <FormFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  submenuId={id}
                  validateField={validateField}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />

      <ProjectReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
