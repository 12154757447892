import React from 'react';
import { Field } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI } from '../Interface';

const FormFields = ({ viewField, values, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="quantityUnit"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.quantityUnit} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.quantityUnit !== '' }}
        />
      </div>
      <div className="col-span-4 ">
        <Field
          type="text"
          name="quantityUnitName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Rövid név" />}
          variant="standard"
          validationMessage={<FormErrorUI message={errors.quantityUnitName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.quantityUnitName !== '' }}
        />
      </div>
    </div>
  );
};

export default FormFields;
