import { Tooltip } from '@mui/material';
import TooltipContent from './TooltipContent';
import ChipContent from './ChipContent';

const EventChip = ({ eventInfo }) => {
  return (
    <div>
      <Tooltip
        placement="bottom"
        title={<TooltipContent eventInfo={eventInfo} />}
        slotProps={{
          tooltip: {
            sx: {
              color: '#514E6A',
              backgroundColor: '#ffff',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              matgin: '1rem',
            },
          },
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}
      >
        <div className="font-bold truncate my-auto px-1">
          <ChipContent eventInfo={eventInfo} />
        </div>
      </Tooltip>
    </div>
  );
};

export default EventChip;
