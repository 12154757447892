import { useState, useEffect } from 'react';

import AddDialog from './AddDialog';
import projectGroupService from '../../service/projectGroup.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { ButtonUI, DataGridUI, DeleteDialogUI, OrderArrowsUI } from '../Interface';

export default function FormTable(props) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [addModalShow, setAddModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const defVal = [props.selectedValues?.defaultValue ?? undefined];
    setSelectedRows(defVal);
    props.setRefetchTable(true);
  }, [props.selectedValues]);

  const removeProjectGroupItem = async (itemId) => {
    projectGroupService
      .deleteProjectGroupItem(itemId)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.WARNING,
        });
        props.setprojectGroupList(props.projectGroupList.filter((item) => item.itemId !== itemId));
        props.setRefetchTable(true);
      })
      .finally(() => {
        setShowDeleteDialog(false);
      });
  };

  const deleteIcon = (props, params) => {
    return (
      <div
        disabled={props.disabled}
        className={props.disabled ? 'hidden' : 'block cursor-pointer'}
        onClick={(e) => {
          e.stopPropagation();
          setRemoveId(params.row.itemId);
          setShowDeleteDialog(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-[20px] h-[20px]"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line> <line x1="6" y1="6" x2="18" y2="18"></line>{' '}
        </svg>
      </div>
    );
  };

  const handleRowClick = (params) => {
    if (!props.viewField) {
      setSelectedItem(params.row);
      setAddModalShow(true);
    }
  };

  const addDefaultValue = async (itemId) => {
    try {
      await projectGroupService.updateDefaultValue(props.projectGroupStatusId, itemId);
      if (itemId !== null) {
        addNotification({
          content: 'Alapértelmezett érték frissítve!',
          type: NotificationType.SUCCESS,
        });
      }
    } catch (error) {
      addNotification({
        content: 'Hiba történt az alapértelmezett érték frissítése közben!',
        type: NotificationType.ERROR,
      });
    }
  };

  const orderMachine = (itemId, newOrder) => {
    projectGroupService.orderGroupItem(itemId, { newOrder }).then(() => {
      props.setRefetchTable(true);
    });
  };

  const handleOrderChange = (itemId, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;

    const updatedList = props.projectGroupList
      .map((item) => {
        if (item.itemId === itemId) {
          return { ...item, visOrder: newOrder };
        } else if (item.visOrder === newOrder) {
          return { ...item, visOrder: currentOrder };
        }
        return item;
      })
      .sort((a, b) => a.visOrder - b.visOrder);
    props.setprojectGroupList(updatedList);

    orderMachine(itemId, newOrder);
  };
  const handleCellClick = (param, event) => {
    if (param.field === 'visOrder') {
      event.stopPropagation();
      return;
    }
  };

  const columns = [
    {
      field: 'itemId',
      headerName: 'Azonosító',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
    },
    {
      field: 'itemName',
      headerName: 'Megnevezés',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      valueGetter: (params) => {
        return params.value ? params.value : 'Nincs adat.';
      },
    },
    {
      field: 'visOrder',
      headerName: 'Sorrend',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      renderCell: (params) => {
        const handleUpClick = () => handleOrderChange(params.row.itemId, params.row.visOrder, 'up');
        const handleDownClick = () => handleOrderChange(params.row.itemId, params.row.visOrder, 'down');
        return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={props.viewField} />;
      },
    },
    {
      field: 'removeTag',
      headerName: '',
      flex: 0.4,
      headerClassName: 'bg-tableHeaderColor',
      renderCell: (params) => {
        return deleteIcon(props, params);
      },
    },
  ];

  return (
    <div className=" sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Elemek</h1>
          <p className="mt-2 text-sm text-gray-700">További elemek hozzáadása.</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <ButtonUI
            text="Hozzáadás"
            type="button"
            disabled={props.disabled}
            onClick={() => {
              setAddModalShow(true);
            }}
            className="block rounded-md bg-success px-3 disabled:cursor-not-allowed disabled:opacity-50 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          ></ButtonUI>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <DataGridUI
                sx={{
                  height: 440,
                  width: '100%',
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeaderDraggableContainer': {
                    backgroundColor: '#CEDFE8',
                  },
                }}
                columns={columns}
                rows={props.projectGroupList}
                getRowId={(row) => row.itemId}
                onRowClick={handleRowClick}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  if (newRowSelectionModel.length > 0) {
                    setSelectedRows([newRowSelectionModel[newRowSelectionModel.length - 1]]);
                    addDefaultValue(newRowSelectionModel[newRowSelectionModel.length - 1]);
                  } else {
                    setSelectedRows([]);
                    addDefaultValue(null);
                  }
                }}
                selectedRows={selectedRows}
                checkboxSelection
                isRowSelectable={(params) => !props.viewField}
                rowSelectionModel={selectedRows}
                disableRowSelectionOnClick={true}
                onCellClick={handleCellClick}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteDialogUI
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        handleRemoveElement={() => removeProjectGroupItem(removeId)}
      />

      <AddDialog
        show={addModalShow}
        projectGroupList={props.projectGroupList}
        setprojectGroupList={props.setprojectGroupList}
        onHide={() => {
          setAddModalShow(false);
          setSelectedItem({});
        }}
        projectGroupStatusId={props.projectGroupStatusId}
        setRefetchTable={props.setRefetchTable}
        selectedItem={selectedItem}
      />
    </div>
  );
}
