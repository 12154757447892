import React from 'react';
import { DialogUI } from '../Interface';
import { CreateSimpleTask } from '../SimpleTaskDialog/CreateSimpleTask/CreateSimpleTask';
const CreateTaskDialog = ({ open, handleClose, create }) => {
  const { taskCreated, setTaskCreated } = create;
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Új feladat rögzítése"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <CreateSimpleTask handleClose={handleClose} taskCreated={taskCreated} setTaskCreated={setTaskCreated} />
      </div>
    </DialogUI>
  );
};

export default CreateTaskDialog;
