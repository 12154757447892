import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import _ from 'lodash';
import dayjs from 'dayjs';
import productionService from '../../service/production.service';
import { useLoader } from '../../provider/LoaderProvider';
import { ProductionInfoPanel } from './InfoPanel';
import { ProductionSearch } from './ProductionSearch';
import { ProductionTabs } from './FormTabs';
import { OperationReportTable } from './OperationReportTable';
import { RejectReportTable } from './RejectReportTable';
import { FormButtonPanelUI } from '../Interface';
import { ProductionReportDialog } from './ProductionReportDialog';

export const ProductionReportForm = () => {
  const { showLoader, hideLoader } = useLoader();
  const [activeTab, setActiveTab] = useState(0);
  const [productionList, setProductionList] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [refetchTable, setRefetchTable] = useState(true);
  const [owner, setOwner] = useState({});

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [filterFormState, setFilterFormState] = useState({
    searchQuery: '',
  });

  useEffect(() => {
    if (productionList.length > 1) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [productionList]);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('user');
    const userObj = JSON.parse(isAuthenticated);
    setOwner(userObj);

    const def = {
      date: dayjs(new Date()),
      quantity: '',
      netWeight: '',
      packingWeight: '',
      timeSpent: '',
      userId: null,
    };

    if (isProductionListObject()) {
      setOwner(userObj);
      setDefaultSelectedValues({ user: userObj });
    } else {
      setOwner({});
      setDefaultSelectedValues({});
      setDefaultValues(def);
    }
  }, [productionList]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: owner.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: owner,
      };
    });
  }, [owner]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().positive().required(),
    netWeight: Yup.number().positive().required(),
    packingWeight: Yup.number().positive().required(),
    timeSpent: Yup.number().required().nullable(),
    userId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { date, quantity, netWeight, packingWeight, timeSpent, userId } = selectedValues;

    setInitValues({
      date: dayjs(date),
      quantity,
      netWeight,
      packingWeight,
      timeSpent,
      userId,
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    const prodOperationId = productionList.prodOperationId;
    const insertObject = {
      ...values,
      date: dayjs(values.date).toISOString(),
    };

    productionService
      .createProductionReport(prodOperationId, insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setInitValues({});
        setSelectedValues(defaultValues);
      })

      .finally(() => {
        hideLoader();
      });
  };

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const isProductionListObject = () => {
    return !Array.isArray(productionList) && Object.keys(productionList).length > 0;
  };

  const handleSelectionChange = (selectedRowData) => {
    setProductionList(selectedRowData);
    setFilterFormState({ searchQuery: selectedRowData.operationIdlabel });
    setShowDialog(false);
  };

  return (
    <div className="p-4 bg-gray-100">
      <div className="bg-white p-3 rounded-lg pb-5">
        <ProductionSearch
          setProductionList={setProductionList}
          refetch={{ refetchTable, setRefetchTable }}
          filter={{ filterFormState, setFilterFormState }}
        />
        <ProductionInfoPanel productionList={productionList} />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 pt-5">
        <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
          <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleInsertObject}
              enableReinitialize
            >
              {({ values, setFieldValue, errors, validateField, resetForm }) => (
                <Form>
                  <ProductionTabs
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    validateField={validateField}
                    productionList={productionList}
                  />
                  {isProductionListObject() && (
                    <FormButtonPanelUI
                      addClick={() => {
                        setSelectedValues(defaultSelectedValues);
                        setInitValues(defaultValues);
                        resetForm();
                      }}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {activeTab === 0 && (
          <OperationReportTable productionList={productionList} refetch={{ refetchTable, setRefetchTable }} />
        )}
        {activeTab === 1 && <RejectReportTable />}
        <ProductionReportDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          production={{ productionList, setProductionList }}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </div>
  );
};
