import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import projectService from '../../service/project.service';
import { dataGridColumns } from '../../utils/helper';
import { generateFilter } from '../../utils/helper';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, DataGridUI } from '../Interface';

export default function ProjectReports({ refetch, values, viewField, operatorsDisabled }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'projectStartDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    projectStartDate: null,
    projectEndDate: null,
    userId: '',
    statusId: '',
    companyId: '',
    projectName: '',
    projectGroupId: '',
  });

  const conditions = {
    like: ['projectName'],
    gte: ['projectStartDate'],
    lte: ['projectEndDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];
    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    projectService.getProjects(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'projectName',
      headerName: 'Projekt neve',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0.5,
    },
    {
      field: 'companyName',
      headerName: 'Cég',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.company?.companyName;
      },
    },
    {
      field: 'statusName',
      headerName: 'Státusz',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.status?.itemName;
      },
    },
    {
      field: 'startDate',
      headerName: 'Kezdő időpont',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0.3,
      valueGetter: (data) => {
        return dayjs(data.row.projectStartDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'endDate',
      headerName: 'Befejező időpont',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0.3,
      valueGetter: (data) => {
        return dayjs(data.row.projectEndDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'groupitemName',
      headerName: 'Projekt csoport',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0.3,
      sortable: false,
      valueGetter: (data) => {
        return data.row.projectGroup?.itemName;
      },
    },
    {
      field: 'projectPrepare',
      headerName: '%',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        const preparedness = data.row.projectPrepare;
        return preparedness > 0 ? `${preparedness}%` : '';
      },
    },
    {
      field: 'userName',
      headerName: 'Tulajdonos',
      headerClassName: 'bg-tableHeaderColor',
      flex: 0,
      sortable: false,
      valueGetter: (data) => {
        return data.row.user?.userName;
      },
    },
  ]);

  return (
    <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
        <div className="flex h-full overflow-hidden border-t border-gray-200">
          <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
            <div className="sticky top-0 bg-white">
              <div className="pt-2">
                <div className="grid grid-cols-4 gap-x-4 mx-4">
                  <div className="col-span-2 md:col-span-1">
                    <DatePickerUI
                      label={<span className="text-labelColor">Kezdő dátum</span>}
                      value={filterFormState.projectStartDate}
                      onChange={(newDate) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          projectStartDate: newDate ? newDate.startOf('day') : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <DatePickerUI
                      label={<span className="text-labelColor">Befejező dátum</span>}
                      value={filterFormState.projectEndDate}
                      onChange={(newDate) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          projectEndDate: newDate ? newDate.endOf('day') : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AutoCompleteSelectUI
                      id="worker"
                      label={<span className="text-labelColor">Tulajdonos</span>}
                      selectedValue={filterFormState.userId}
                      table="WebUser"
                      listType={{ id: 'userId', name: 'userName' }}
                      onChange={(_e, newVal, reason) => {
                        if (reason === 'clear') {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            userId: '',
                          }));
                        } else {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            userId: newVal.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AutoCompleteSelectUI
                      id="Status"
                      label={<span className="text-labelColor">Státusz</span>}
                      table="statusitems"
                      selectedValue={filterFormState.statusId}
                      listType={{ id: 'itemId', name: 'itemName' }}
                      onChange={(_e, newVal, reason) => {
                        if (reason === 'clear') {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            statusId: '',
                          }));
                        } else {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            statusId: newVal.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AutoCompleteSelectUI
                      id="companyId"
                      label={<span className="text-labelColor">Cég</span>}
                      table="company"
                      selectedValue={filterFormState.companyId}
                      listType={{ id: 'companyId', name: 'companyName' }}
                      onChange={(_e, newVal, reason) => {
                        if (reason === 'clear') {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            companyId: '',
                          }));
                        } else {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            companyId: newVal.value,
                          }));
                        }
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <InputUI
                      label="Projekt neve"
                      InputLabelProps={{
                        style: { color: '#607d8b' },
                      }}
                      value={filterFormState.projectName}
                      variant="standard"
                      onChange={(e) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          projectName: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <AutoCompleteSelectUI
                      id="shift"
                      label={<span className="text-labelColor">Projekt csoport</span>}
                      table="ProjectGroupListitems"
                      selectedValue={filterFormState.projectGroupId}
                      listType={{ id: 'itemId', name: 'itemName' }}
                      onChange={(_e, newVal, reason) => {
                        if (reason === 'clear') {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            projectGroupId: '',
                          }));
                        } else {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            projectGroupId: newVal.value,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
              <div className="z-40 min-w-full h-[100%]">
                <DataGridUI
                  initialState={query}
                  rows={reports.rows}
                  rowCount={reports.rowCount}
                  columns={columns}
                  onRowClick={(rowData) => {
                    if (!viewField) return;
                    setSelectedValues(rowData.row);
                  }}
                  paginationMode="server"
                  onPaginationModelChange={onPaginitonChange}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick={!viewField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
