import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Yup from '../../utils/yup';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import statusService from '../../service/status.service';
import { ColorPicker } from '../../config/ColorPicker';

import ColorPickerUI from '../Interface/ColorPickerUI';
import { ButtonUI, DialogUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';

const AddDialog = ({ setstatusList, onHide, show, handleOpen, statusId, setRefetchTable, selectedItem }) => {
  const [color, setColor] = useState({});
  const [formData, setFormData] = useState({});
  const isEditMode = Object.keys(selectedItem).length > 0;

  const defaultValues = {
    itemName: '',
  };

  const defaultColor = {
    colorCode: '#fff',
    colorName: 'white',
  };

  const validationSchema = Yup.object().shape({
    itemName: Yup.string().required(),
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        itemName: selectedItem.itemName,
      });

      const foundColor = ColorPicker.find((colorItem) => colorItem.colorName === selectedItem.color);
      setColor(foundColor);
    } else {
      setFormData(defaultValues);
      setColor(defaultColor);
    }
  }, [selectedItem, isEditMode]);

  useEffect(() => {
    setColor(defaultColor);
    setFormData(defaultValues);
  }, []);

  const handleAddStatusItem = async (values) => {
    const insertObject = {
      ...values,
      statusId: statusId,
    };

    try {
      const data = await statusService.createStatusItem(insertObject);
      setstatusList((prevList) => [...prevList, data]);
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const updateStatusItem = async (values) => {
    try {
      const updatedItem = await statusService.updateStatusItem(values, selectedItem.itemId);
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setstatusList((prevList) => prevList.map((item) => (item.itemId === updatedItem.itemId ? updatedItem : item)));
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const handleCloseDialog = () => {
    onHide();
    setColor('#fff');
  };

  const handleSave = (values) => {
    const dbValues = { ...values, color: color.colorName };
    if (Object.keys(selectedItem).length > 0) {
      updateStatusItem(dbValues);
    } else {
      handleAddStatusItem(dbValues);
    }
  };

  return (
    <DialogUI
      open={show}
      handleOpen={handleOpen}
      headerContent={isEditMode ? 'Elem szerkesztése' : 'Új elem felvitele a listába'}
      fullWidth={true}
    >
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSave(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="relative my-3">
              <div className="mt-2 flex gap-10 items-center justify-center z-50">
                <div className="relative">
                  <div className="relative">
                    <Field
                      type="text"
                      as={InputUI}
                      variant="standard"
                      name="itemName"
                      id="itemName"
                      label={<FormLabelUI text="Megnevezés" />}
                      validationMessage={<FormErrorUI message={errors.itemName} />}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <ColorPickerUI
                    color={color.colorCode}
                    changeColor={(ret) => {
                      setColor(ret);
                    }}
                    palette={ColorPicker}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 mt-8 flex justify-center gap-3">
              <ButtonUI
                type="submit"
                text={isEditMode ? 'Mentés' : 'Hozzáadás'}
                className="bg-success"
                disabled={isSubmitting}
              ></ButtonUI>
              <ButtonUI
                text="Mégse"
                className=""
                color="blue-gray"
                variant="gradient"
                onClick={handleCloseDialog}
                disabled={isSubmitting}
              ></ButtonUI>
            </div>
          </Form>
        )}
      </Formik>
    </DialogUI>
  );
};

export default AddDialog;
