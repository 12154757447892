import ButtonUI from './ButtonUI';

const FormHeaderUI = ({
  addClick,
  modClick,
  deleteClick,
  copyClick,
  reportClick,
  addDisabled,
  modDisabled,
  deleteDisabled,
  copyDisabled,
  reportDisabled,
  ...props
}) => {
  return (
    <>
      <div className="flex gap-2 p-3 flex-wrap">
        {addClick && (
          <ButtonUI className="bg-success py-3" text="Hozzáadás" size="sm" disabled={addDisabled} onClick={addClick} />
        )}
        {modClick && (
          <ButtonUI
            className="bg-warning py-3"
            text="Szerkesztés"
            size="sm"
            disabled={modDisabled}
            onClick={modClick}
          />
        )}
        {deleteClick && (
          <ButtonUI
            className="bg-danger py-3"
            text="Törlés"
            size="sm"
            disabled={deleteDisabled}
            onClick={deleteClick}
          />
        )}
        {copyClick && (
          <ButtonUI className="bg-primary py-3" text="Másolás" size="sm" disabled={copyDisabled} onClick={copyClick} />
        )}
        {reportClick && (
          <ButtonUI
            className="bg-labelColor py-3"
            text="Lejelentés"
            size="sm"
            disabled={reportDisabled}
            onClick={reportClick}
          ></ButtonUI>
        )}
      </div>
    </>
  );
};

export default FormHeaderUI;
