import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'kanban/task';

const kanbanTaskService = {
  getTasks: (allColumns) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/tasks/${allColumns}`,
    });
  },
};
export default kanbanTaskService;
